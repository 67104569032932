import React from "react";
import { images } from "../../../constants";

const Hero = () => {
  return (
    <section className="relative bg-white py-20">
      <div className="container mx-auto flex justify-center">
        {/* Card */}
        <div className="relative bg-white rounded-xl shadow-lg overflow-hidden w-full max-w-4xl">
          {/* Image Section */}
          <div className="relative h-60 md:h-80">
            <img
              src={images.Asians}
              alt="AI Hero"
              className="w-full h-full object-cover"
            />
            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-40"></div>
            {/* Text Overlay */}
            <div className="absolute inset-0 flex items-center justify-center text-center">
              <h1 className="text-white font-bold text-2xl md:text-4xl">
                Coming Soon
              </h1>
            </div>
          </div>

          {/* Content Section */}
          <div className="p-6 md:p-10">
            <p className="text-gray-600 text-lg md:text-xl text-center">
              Mentor Matching App, Bootcamp, Coffee Chat and more
            </p>

            {/* Search Bar */}
            <div className="flex flex-col gap-4 mt-6 items-center">
              <button className="bg-primary text-white font-semibold rounded-lg px-6 py-3 shadow hover:bg-opacity-90 transition">
                Let's be leaders
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
